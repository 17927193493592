<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer title="变更记录" width="640" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div>
                <a-timeline>
                    <a-timeline-item v-for="(item,key) in log_list" :key="key" :color="colorStatus(item)">
                        <p>{{item.title}}</p>
                        <p v-for="(i,k) in item.content" :key="k">{{ i }}</p>
                        <p>处理人：{{ item.admin_name }}</p>
                        <p>处理时间：{{ item.create_time }}</p>
                    </a-timeline-item>
                </a-timeline>
            </div>
        </a-drawer>
    </div>
</template>
<script>

    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'history_time', 'history_item'],
        data() {
            return {
                log_list: [],
                history_i: [],
                labelCol: {span: 4},
                wrapperCol: {span: 14},
                form: {
                    name: '',
                    order: ''
                },
            }
        },
        watch: {
            history_time() {
                this.mountedDo()
            },
        },
        mounted() {
            this.mountedDo()
        },
        methods: {
            //icon颜色
            colorStatus(item) {
                if (item.title === '修改内容') return 'blue'
                if (item.title === '样品创建') return 'green'
                if (item.title === '采集中') return 'green'
                if (item.title === '已到达') return 'green'
                if (item.title === '已接收') return 'green'
                if (item.title === '快递中') return 'blue'
                if (item.title === '退回') return 'red'
                if (item.title === '采集完成') return 'green'
                return ''
            },

            mountedDo() {
                this.history_i = JSON.parse(JSON.stringify(this.history_item));
                this.$sa0.post({
                    url: this.$api('Get_samplelog'),
                    data: {
                        sample_id: this.history_i.id,
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.log_list = response.data.list
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })

            },
            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },
            onSubmit() {
                console.log('submit!', this.form);
            },

        }
    }
</script>
<style scoped>
    #components-form-demo-validate-other .dropbox {
        height: 180px;
        line-height: 1.5;
        }

</style>
