<template>
    <div>
        <a-row type="flex" style="flex-flow: row">
            <a-col flex="250px" class="bgc">
                <div class="samplelefttit">
                    委托单列表
                </div>
                <div>
                    <a-tree :tree-data="node_list" :replaceFields="{children:'child', title:'name', key:'id' }" :show-line="showLine" :show-icon="showIcon" @select="onSelect">
                        <a-icon slot="icon" type="carry-out"/>
                    </a-tree>


                </div>
            </a-col>
            <a-col style="flex: 1">
                <a-form-model layout="inline" :model="formInline" @submit="handleSubmit" @submit.native.prevent>
                    <a-form-model-item label="内部编号">
                        <a-input v-model="formInline.user" class="w200"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="外部编号">
                        <a-input v-model="formInline.user" class="w200"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="样品类型">
                        <a-select v-model="formInline.region" placeholder="请选择样品类型" class="w200">
                            <a-select-option value="shanghai">
                                样品类型1
                            </a-select-option>
                            <a-select-option value="beijing">
                                样品类型2
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item label="来源单位">
                        <a-input v-model="formInline.danwei" class="w200"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="联系人">
                        <a-input v-model="formInline.people" class="w200"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="联系电话">
                        <a-input v-model="formInline.tel" class="w200"></a-input>
                    </a-form-model-item>
                    <a-form-model-item label="">
                        <a-button type="primary">
                            搜索
                        </a-button>
                    </a-form-model-item>
                </a-form-model>
                <div class="layer-box">
                    <div class="layer">
                        <table class="layui-table">
                            <tr>
                                <th width="250">样品名称</th>
                                <th width="250">状态</th>
                                <th width="250">内部编号</th>
                                <th width="250">外部编号</th>
                                <th width="400">样品来源单位</th>
                                <th width="250">联系人</th>
                                <th width="250">电话</th>
                                <th width="250">采样时间</th>
                                <th width="250">收样时间</th>
                                <th width="250">接收负责人</th>
                                <th width="250">采集地点</th>
                                <th width="250">接收负责人</th>
                                <th width="300">备注</th>
                                <th>
                                    <div class="zhanwei"></div>
                                </th>
                                <th class="td-1">操作</th>
                            </tr>
                            <tr>
                                <td>样品名称</td>

                                <td>
                                    <a-badge status="error"/>
                                    已退回
                                </td>
                                <td>23645823645895479547</td>
                                <td>2364589547</td>
                                <td>科兴有限公司</td>
                                <td>李先生</td>
                                <td>1884546954</td>
                                <td>2012-5-21 15:23:25</td>
                                <td>2012-5-21 15:23:25</td>
                                <td>李先生</td>
                                <td>第一实验室</td>
                                <td>第一实验室</td>
                                <td>
                                    <a-tooltip>
                                        <template slot="title">
                                            备注信息全部信息备注信息全部信息备注信息全部信息
                                        </template>
                                        备注信息
                                    </a-tooltip>
                                </td>
                                <td>
                                    <div class="zhanwei"></div>
                                </td>
                                <td class="td-1">
                                    <a-button type="link" class="ml5" @click="showDrawer()">
                                        查看变更记录
                                    </a-button>
                                    <a-button class="ml5" type="primary" @click="showCreatsample">编辑</a-button>

                                    <a-popconfirm title="确定删除该样品吗?" ok-text="Yes" cancel-text="No">
                                        <a-button class="ml5" type="danger">删除</a-button>
                                    </a-popconfirm>


                                </td>
                            </tr>


                        </table>


                    </div>
                </div>
                <div style="margin-top: 20px">
                    <a-pagination v-model="current" :total="50" show-less-items/>
                </div>


            </a-col>
        </a-row>
        <!--创建样品分类弹窗-->

        <!--变更记录弹窗组件-->
        <Biangenglist :close="onCloseDrawer" :visible="drawer_visible"></Biangenglist>
        <!--创建编辑样品弹窗组件-->
        <Creatsample :close="onCloseCreatsample" :visible="creatsample_visible"></Creatsample>
    </div>

</template>

<script>
    // 变更记录
    import Biangenglist from '../../../components/drawer/biangenglist/index.vue';
    // 编辑弹窗
    import Creatsample from '../../../components/drawer/creatsample/index.vue';

    export default {
        props: ['visible', 'close'],
        components: {Biangenglist, Creatsample},
        data() {
            return {
                current: 1,
                showLine: true,
                showIcon: false,
                // 定义控制抽屉显示的变量
                drawer_visible: false,
                creatsample_visible: false,
                labelCol: {span: 4},
                wrapperCol: {span: 14},
                node_list: [],
                pid: 0,
                form: {
                    name: '',
                    region: undefined,
                },
                formInline: {
                    user: '',
                    password: '',
                    region: undefined,
                    danwei: '',
                    people: '',
                    tel: '',
                },
            };
        },
        watch: {
            $route: {
                handler() {
                    this.Templatealllist()  //获取委托单分级列表
                    this.mountedDo()
                },
                deep: true
            }
        },

        mounted() {
            this.Templatealllist()  //获取委托单分级列表
            this.mountedDo()
        },
        methods: {
            Templatealllist() {
                this.$sa0.post({
                    url: this.$api('Templatealllist'),
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.node_list = response.data.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },


            handleSubmit(e) {
                console.log(this.formInline);
            },
            // 定义 关闭抽屉时的 函数
            onCloseDrawer() {
                this.drawer_visible = false;
            },
            // 定义 打开抽屉时的 函数
            showDrawer() {
                this.drawer_visible = true;
            },
            // 定义 关闭抽屉时的 函数
            onCloseCreatsample() {
                this.creatsample_visible = false;
            },

            onSelect(selectedKeys) {
                if (selectedKeys.length == 0) return false;
                if (Number(this.pid) === Number(selectedKeys[0])) return false;
                this.$router.push('/caijijieshouyangpinguanli/index/' + selectedKeys[0])
            },
            showCreatsample() {
                this.creatsample_visible = true;
            },
        },
    };
</script>

<style scoped>

    .bgc{ margin-right: 20px; border: 1px solid #eeeeee;}

    .samplelefttit{ background: #f9f9f9; padding: 10px; border-bottom: 1px solid #eeeeee; font-weight: bold }

    .addbutton{ width: 100%; padding: 20px; text-align: center}

    .w200{ width: 200px}

    td, th{
        color: #333;
        font-size: 14px;
        line-height: 30px;
        box-sizing: border-box;
        white-space: nowrap;
        background: #fff;
        }

    .layer-box{
        width: 1750px;
        /*width: 100%;*/
        overflow: hidden;
        position: relative;
        }

    .layer{
        width: 100%;
        overflow: hidden;
        overflow-x: scroll;
        }

    .td-1{
        position: absolute;
        right: 0;
        z-index: 2;
        width: 300px;
        }

    .zhanwei{
        width: 270px;
        }

    table tr:nth-child(odd) th{
        background: #f7f8f9;}

    .ml5{ margin-left: 5px}

</style>
