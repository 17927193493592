<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer :title="`${info.id===0?'新建':'编辑'}样品`" width="800" placement="right" :closable="true" :visible="visible" @close="onClose">
            <a-form-model :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item label="样品类型">
                    <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }" v-model="info.category_ids" :options="options" placeholder="请选择" class="w300"/>
                    <span @click="getStype()" class="ant-form-text"><a-icon type="sync"/></span>
                    <a-button type="primary" @click="showModal()">
                        创建样品分类
                    </a-button>
                </a-form-model-item>
                <a-form-model-item label="内部编号">
                    <a-input v-model="info.inter_code" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="采集名称">
                    <a-input v-model="info.caiji_name" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="外部编号">
                    <a-input v-model="info.source_code" class="w300"/>
                </a-form-model-item>
<!--                <a-form-model-item label="样品状态" v-if="info.id !==0">-->
                <a-form-model-item label="样品状态">
                    <a-select v-model="info.status" placeholder="请选择样品状态" class="w300" :value="info.id">
                        <a-select-option value="1">
                            未采集
                        </a-select-option>
                        <a-select-option value="2">
                            采集中
                        </a-select-option>
                        <a-select-option value="3">
                            已到达
                        </a-select-option>
                        <a-select-option value="4">
                            已接收
                        </a-select-option>
                        <a-select-option value="5">
                            快递中
                        </a-select-option>
                        <a-select-option value="6">
                            退回
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="来源单位">
                    <a-input v-model="info.source_unit" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="联系人">
                    <a-input v-model="info.contact_name" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="电话">
                    <a-input v-model="info.phone" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="采集时间">
                    <a-date-picker show-time placeholder="请选择" :value="info.caiji_time" @change="(e,dateString)=>{onChangetime(e,dateString,'caiji_time')}" @ok="onOk" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="采集地点">
                    <a-input v-model="info.collection_address" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="采集类型">
                    <a-select v-model="info.received" placeholder="请选择样品状态" class="w300" :value="info.id">
                        <a-select-option :value="1">
                            来源送达
                        </a-select-option>
                        <a-select-option :value="2">
                            快递
                        </a-select-option>
                        <a-select-option :value="3">
                            自采
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="采集负责人" v-if="Number(info.received) === 3">
                    <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }" v-model="info.caiji_ids" :options="fuzerenlist" placeholder="请选择" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="收样时间">
                    <a-date-picker show-time placeholder="请选择" :value="info.receive_time" @change="(e,dateString)=>{onChangetime(e,dateString,'receive_time')}" @ok="onOk" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="接收负责人">
                    <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }" v-model="info.danda_ids" :options="fuzerenlist" placeholder="请选择" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="存放地址">
                    <a-select v-model="info.store_address" placeholder="请选择" class="w300">
                        <a-select-option v-for="(i,k ) in cfaddress" :key="k" :value="i.id">{{
                            i.name }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="备注">
                    <a-textarea v-model="info.remark" placeholder="" :rows="4"/>
                </a-form-model-item>
                <!--                                <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">-->
                <!--                                    <a-button type="primary" @click="test()">-->
                <!--                                        测试-->
                <!--                                    </a-button>-->
                <!--                                </a-form-model-item>-->
                <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                    <a-button type="primary" @click="doClick()">
                        确定
                    </a-button>
                </a-form-model-item>
            </a-form-model>
        </a-drawer>


        <div v-if="create_info">
            <Createsamplelist :chooseDo="chooseDo" :create_time="create_time" :create_info="create_info" :ok="modalClose" :cancel="modalClose" :visible="modal_visible"></Createsamplelist>
        </div>

    </div>
</template>
<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    import Createsamplelist from '../../../components/drawer/createsamplelist/index.vue';

    export default {
        components: {
            Createsamplelist
        },
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'edit_info', 'edit_time',],
        data() {
            return {
                info: {
                    id: 0,
                    caiji_name: '',
                    inter_code: '',
                    source_code: '',
                    source_unit: '',
                    contact_name: '',
                    phone: '',
                    caiji_time: null,
                    collection_address: '',
                    receive_time: null,
                    received: '',
                    danda_id: [],
                    danda_ids: [],
                    caiji_id: [],
                    caiji_ids: [],
                    category_id: [],
                    category_ids: [],
                    status: '',
                    store_address: '',
                    remark: '',
                },
                creatsample_visible: false,
                modal_visible: false,
                labelCol: {span: 4},
                wrapperCol: {span: 14},
                options: [],
                fuzerenlist: [],
                cfaddress: [],
                caijifuzerenlist: [],
                create_info: false,
                create_time: 0,
            }
        },
        watch: {
            edit_time() {
                this.info = JSON.parse(JSON.stringify(this.edit_info))
                // this.info.store_address = Number(this.info.store_address)
            },
        },
        mounted() {
            this.Samplecategoryalllist() //获取样品类型所有分级列表
            this.Dandaalllist() //获取部门人员所有分级列表
            this.Sampletypelist()  //存放地点
            this.info = this.edit_info  //上个组件传过来接收
            // this.info.store_address = Number(this.info.store_address)
            console.log(this.info)
        },
        methods: {
            chooseDo(auto, ids) {
                this.Samplecategory()
                if (auto) {
                    this.info.category_ids = ids
                }
                this.modalClose()
            },
            // test() {
            //     console.log(this.info.store_address)
            // },
            // 获取样品类型所有分级列表
            Samplecategoryalllist() {
                this.$sa0.post({
                    url: this.$api('Samplecategoryalllist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.options = response.data.list;
                            console.log(this.options)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 项目负责人所有列表
            Dandaalllist() {
                this.$sa0.post({
                    url: this.$api('Dandaalllist'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.fuzerenlist = response.data.list;
                            // console.log(this.options)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 存放地点
            Sampletypelist() {
                this.$sa0.post({
                    url: this.$api('Sampletypelist'),
                    data: {
                        type: 3
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.cfaddress = response.data.list
                            // console.log('cfaddress', this.cfaddress)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
// 保存按钮
            doClick() {
                console.log('this.info.caiji_ids--------------', this.info.caiji_ids)
                let data = {
                    id: this.info.id,
                    category_id: this.info.category_ids[this.info.category_ids.length - 1],
                    inter_code: this.info.inter_code,
                    source_code: this.info.source_code,
                    caiji_name: this.info.caiji_name,
                    source_unit: this.info.source_unit,
                    contact_name: this.info.contact_name,
                    phone: this.info.phone,
                    caiji_time: this.info.caiji_time,
                    collection_address: this.info.collection_address,
                    receive_time: this.info.receive_time,
                    received: this.info.received,
                    danda_id: this.info.danda_ids[this.info.danda_ids.length - 1],
                    caiji_id: this.info.caiji_ids.length === 0 ? 0 : this.info.caiji_ids[this.info.caiji_ids.length - 1],
                    status: this.info.status,
                    store_address: this.info.store_address,
                    remark: this.info.remark,
                }
                console.log(data)
                console.log(JSON.stringify(data))
                this.$sa0.post({
                    url: Number(this.info.id) !== 0 ? this.$api('Edit_sample') : this.$api('Create_sample'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            this.close()
                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },
// 刷新样品分类
            getStype() {
                this.$sa0.post({
                    url: this.$api('sampletypelist'),
                    data: {
                        type: '1'
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.type_list = response.data.list
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },
            onSubmit() {
                console.log('submit!', this.form);
            },
            onChange(value) {
                console.log(value);
            },
            onChangetime(value, dateString, e) {
                this.info[`${e}`] = dateString;
            },
            onOk(value) {
                console.log('onOk: ', value);
            },
            // 定义 关闭对话框时的 函数
            modalClose() {
                this.modal_visible = false
            },
            // 定义 打开对话框时的 函数
            showModal() {
                this.create_time = new Date() / 1;
                this.create_info = this.info
                this.modal_visible = true
            }
        }
    }
</script>
<style scoped>
    .w300{ width: 250px}

    .ant-form-text{ padding-left: 10px; padding-right: 10px}

</style>
