<template>
    <div>
        <a-modal title="添加样品类型" okText="确定" cancelText="取消" :visible="visible" @ok="handleOk" @cancel="handleCancel">
            <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item label="名称">
                    <a-input v-model="form.name" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="样品类型分类">
                    <a-cascader :fieldNames="{ label: 'name', value: 'id', children: 'child' }" v-model="form.types" :options="options" placeholder="请选择" class="w300"/>
                </a-form-model-item>
                <a-form-model-item label="样品分类">
                    <a-select v-model="form.type_id" placeholder="选择样品分类" class="w300">
                        <a-select-option v-for="(item,key) in type_list" :key="key" :value="item.id">
                            {{ item.name}}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="" :wrapper-col="{ span: 14, offset: 5 }">
                    <a-checkbox-group >
                        <a-checkbox v-model="auto_choose" value="1" name="type">
                            创建之后选中
                        </a-checkbox>
                    </a-checkbox-group>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>
<script>
    /***
     Name: example4
     Code: sa0ChunLuyu
     Time: 2021/10/16 10:19
     Remark: 编辑弹框示例
     */
    export default {
        // 接收 显示变量 和 确定 取消 函数
        props: ['visible', 'ok', 'cancel', 'create_info', 'create_time', 'chooseDo'],

        data() {
            return {
                auto_choose: true,
                labelCol: {span: 5},
                wrapperCol: {span: 16},
                modal_visible: false,
                form: {
                    name: '',
                    order: '',
                    region: '',
                    type: '',
                    types: [],
                },
                options: [],
                type_list: [],
                pids: [],
            }
        },

        watch: {
            create_time() {
                this.info = JSON.parse(JSON.stringify(this.create_info))
            },
        },
        mounted() {
            this.form = this.create_info
            this.Get_sampletype() //获取基础字段列表
            this.Samplecategory() //样品类型文件夹列表
        },
        methods: {
            // 获取基础字段列表 样品分类
            Get_sampletype() {
                this.$sa0.post({
                    url: this.$api('Get_sampletype'),
                    data: {
                        type: 1
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.type_list = response.data.result.list
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            //获取样品分级列表
            Samplecategory() {
                this.$sa0.post({
                    url: this.$api('Samplecategory'),
                    data: {
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.options = response.data.list
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 定义 组件 自身的确定函数
            handleOk() {
                this.$sa0.post({
                    url: this.$api('Create_samplecategory'),
                    data: {
                        name: this.form.name,
                        type_id: this.form.type_id,
                        pid: this.form.types[this.form.types.length - 1],
                        type: 1,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            let ids = this.form.types
                            ids.push(response.data.id)
                            this.chooseDo(this.auto_choose, ids);
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
                console.log('submit!', this.form);
            },
            // 定义 组件 自身的取消函数
            handleCancel() {
                this.cancel()
            },
            // 定义 关闭对话框时的 函数
            modalClose() {
                this.modal_visible = false
            },
            // 定义 打开对话框时的 函数
            showModal() {
                this.modal_visible = true
            },
            onChange(e) {
                console.log(`checked = ${e.target.checked}`);
            },
        }
    }
</script>

<style scoped>

</style>
